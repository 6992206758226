import React, { Component, useState, useEffect } from "react";
import Auth from "actions/auth";
import axios from "axios";
import moment from 'moment';
import { CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, makeStyles, CssBaseline } from "@material-ui/core";
import GridContainer from "components/UI/Grid/GridContainer";
import GridItem from "components/UI/Grid/GridItem";
import CardBody from "components/UI/Card/CardBody";
import Card from "components/UI/Card/Card";
import CardHeader from "components/UI/Card/CardHeader";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

export default () => {
  const [payments, setPayments ] = useState([])
  const [isLoading, setLoading ] = useState(false)
  const classes = useStyles();

  useEffect(()=> {
    fetchPayments()
  }, [])

  const fetchPayments = async () => {
    setLoading(true)
    const isUserLogged = Auth.loginCheck();
    if (!isUserLogged) window.location = "/login";

    const token = JSON.parse(localStorage.getItem("login"));
    if (token) {
      const id = Auth.decode("id");
      const url =`/api/users/payments/${id}`;

      await axios
        .get(url, {
          headers: {
            authorization: token.token
          }
        })
        .then(async ({data}) => {
          // let tutorial = data.tutorials.length ? data.tutorials.filter(tutorial => tutorial.type === TUTORIALS.ADD_GROUP)[0] : null
          console.log(data)
          setPayments(data)
          setLoading(false)
        });
    }
  }
    if(isLoading){
      return <div className='d-flex flex-column align-items-center'>
          <CircularProgress color='inherit' />  
          <p>{'Please wait we are fetching your data.'}</p>
      </div>
    }


    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Payments</h4>
            <p className={classes.cardCategoryWhite}>
              <small>Your all payments</small>
            </p>
          </CardHeader>
          <CardBody>
            <CssBaseline />
            {payments.length === 0 &&  <p>No payment record found.</p>}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Plan Name</TableCell>
                      <TableCell align="right">Order Date</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Order Id</TableCell>
                      <TableCell align="right">Payment Method</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.map((payment) => (
                      <TableRow key={payment._id}>
                        <TableCell component="th" scope="payment">
                          {payment.plan ? payment.plan.name : 'Unknown Plan'}
                        </TableCell>
                        <TableCell align="right">{moment(payment.order_date).format('DD MMM YYYY')}</TableCell>
                        <TableCell align="right">{payment.amount} {payment.currency.toUpperCase()}</TableCell>
                        <TableCell align="right">{payment.order_id}</TableCell>
                        <TableCell align="right">{payment.payment_method}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
     ;
}
