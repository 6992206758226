import axios from "axios";
import React from "react";
import jwt from "jsonwebtoken";

class Auth extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.user = {};
  }

  async login(user) {
    const url =`/api/auth/login`;
    const opt = {
      email: user.email,
      password: user.password
    };
    // localStorage.setItem("login", {login: true,
    //   token: 'res.data.token',})
    //   return { status: true, message: 'Login' };
    return await axios
      .post(url, opt)
      .then(res => {
        const loginItem = {
          login: true,
          token: res.data.token
        };
        localStorage.setItem("login", JSON.stringify(loginItem));
        return { status: true };
      })
      .catch(e => {
        return { status: false, message: e.response.data.message, ...e.response.data };
      });
  }

  decode(field) {
    const login = JSON.parse(localStorage.getItem("login"));
    if (!login) return null;
    const decoded = jwt.decode(login.token);

    return decoded[field];
  }

  logout() {
    localStorage.setItem("login", null);
  }

  loginCheck() {
    const login = localStorage.getItem("login");
    // intended string
    if (login === "null" || login === null) {
      return false;
    }
    return true;
  }
}

export default new Auth();
