import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Auth from "actions/auth";
import Logo from "assets/img/transparent-3.png";
import axios from "axios";
import {regEx } from '../../utility/helpers';
import image1 from 'assets/img/bg-3.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url("+image1+")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "green"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: green[50],
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function ResetPassword(props) {
  const isUserLogged = Auth.loginCheck();
  if (isUserLogged) props.history.push("/admin");

  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [userData, setUserData] = useState({
    id: null,
    token: null
  });
  const [resetPassword, setResetPassword] = useState(false);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([]);
  

  useEffect(()=>{
      let paramsObj = {}
      const params = new URLSearchParams(window.location.search);
      for (const [key, value] of params) {
        paramsObj[key] = value
      }
      if(paramsObj.id && paramsObj.token){
          setResetPassword(true)
          setUserData({
            id: paramsObj.id,
            token:  paramsObj.token
          })
      }else{
        setError([{
          field: 'error',
          msg:  'No user found!! Please click on the email link again.'
        }])
      }
  }, [])

  const handleChange = event => {
    event.preventDefault();
    setError([])
    setLoading(false)
    const { name, value } = event.target;
    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "confirm":
        setConfirm(value);
      default:
        break;
    }
  };

  const formValid = () => {
    const valid = {
      status: true
    };
    const error = [];
    
    if (!password)  error.push({ status: false, msg: "Password required." , field: "password"});
    if (!confirm || confirm !== password) {
      error.push({
        status: false,
        msg: "Password did not match.",
        field: "confirm"
      });
    }

    return error;
  };


  const handleResetPassword = async event => {
    event.preventDefault();
    setLoading(true)
    const errors = formValid()
    if (errors.length !== 0)  {
      setError(errors);
      setLoading(false)
    }else{
      const data = {
        password,
        userId: userData.id,
        token: userData.token,
      };
      axios
        .post(`/api/users/reset-password`, data)
        .then(() => {
          setLoading(false)
          setError([{field: 'success', msg: 'Password reset successfully!'}]);
          props.history.push("/login?msg=Password reset successfully!");
        })
        .catch(e => {
          setLoading(false)
          setError([{field: 'server', msg: e.response.data.message}]);
        });
    }
  };


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} alt="logo" width={"140px"} className={"mb-2"} />
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <form className={classes.form} onSubmit={handleResetPassword } noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="password"
              type="password"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm"
              label="Confirm Password"
              id="confirm"
              autoComplete="confirm"
              type="password"
              onChange={handleChange}
            /> 
            {error.map(e=>{
                if(e.field === 'success') return <FormHelperText key={e.field} variant='filled'  color='primary'>{e.msg}</FormHelperText>
                return <FormHelperText error key={e.field} required variant='filled'  color='secondary'>{e.msg}</FormHelperText>
            })}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={loading && <CircularProgress color='inherit'/>}
              disabled={loading}
            >
               Reset password
            </Button>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
