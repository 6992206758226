import React, {useState, useEffect } from "react";
import Auth from "actions/auth";
import axios from "axios";
import moment from 'moment';
import { CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, makeStyles, CssBaseline, Button } from "@material-ui/core";
import GridContainer from "components/UI/Grid/GridContainer";
import GridItem from "components/UI/Grid/GridItem";
import CardBody from "components/UI/Card/CardBody";
import Card from "components/UI/Card/Card";
import CardHeader from "components/UI/Card/CardHeader";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

export default () => {
  const [users, setUsers ] = useState([])
  const [isLoading, setLoading ] = useState({status: false, message: ''})
  const classes = useStyles();

  useEffect(()=> {
    fetchusers()
  }, [])

  const fetchusers = async () => {
    setLoading({isLoading: true,  message: 'Please wait we are fetching your data.'})
    const isUserLogged = Auth.loginCheck();
    if (!isUserLogged) window.location = "/login";

    const token = JSON.parse(localStorage.getItem("login"));
    if (token) {
      const id = Auth.decode("id");
      const url =`/api/admin/users/not-verified`;

      await axios
        .get(url, {
          headers: {
            authorization: token.token
          }
        })
        .then(async ({data}) => {
          // let tutorial = data.tutorials.length ? data.tutorials.filter(tutorial => tutorial.type === TUTORIALS.ADD_GROUP)[0] : null
          setUsers(data)
          setLoading({isLoading: false,  message: ''})
        });
    }
  }


    return isLoading.status ? <div className='d-flex flex-column align-items-center'>
            <CircularProgress color='inherit' />  
          <p>{isLoading.message}</p>
      </div> :  
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>users</h4>
            <p className={classes.cardCategoryWhite}>
              <small>All Non Verified Users</small>
            </p>
          </CardHeader>
          <CardBody>
            <CssBaseline />
            {users.length === 0 &&  <p>All users approved!.</p>}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>User Email</TableCell>
                      <TableCell >Date Login</TableCell>
                      <TableCell >Action</TableCell>
                      {/* <TableCell align="right">Order Date</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Order Id</TableCell>
                      <TableCell align="right">Invoice Id</TableCell>
                      <TableCell align="right">Payment Method</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell component="th" scope="user">
                          {user.first_name}
                        </TableCell>
                        <TableCell component="th" scope="name">
                          {user.last_name}
                        </TableCell>
                        <TableCell component="th" scope="email">
                          {user.email}
                        </TableCell>
                        <TableCell component="th" scope="email">
                          { moment(user.date_signed).format('DD MMM YYYY')} 
                        </TableCell>
                        <TableCell component="th" scope="email">
                          <Button variant='contained' >Approve</Button>
                        </TableCell>
                        {/* <TableCell align="right">{moment(user.order_date).format('DD MMM YYYY')}</TableCell>
                        <TableCell align="right">{user.amount} {user.currency.toUpperCase()}</TableCell>
                        <TableCell align="right">{user.order_id}</TableCell>
                        <TableCell align="right">{user.invoice_id}</TableCell>
                        <TableCell align="right">{user.payment_method}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
     ;
}
