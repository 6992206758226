import React from 'react'
import Auth from "actions/auth"
import Axios from 'axios';

export default function(WrappedComponent, props) {

  return class extends React.Component {  
      state = {
              id: null,
              token: null,
              user: ''
          }

      async componentDidMount() {
        const token = JSON.parse(localStorage.getItem("login"));
        if (token) {
          const id = Auth.decode("id");
          this.setState({id, token})
        
          const url =`/api/users/${id}`;
          await Axios
          .get(url, {
            headers: {
              authorization: token.token
            }
          })
          .then(async data => {
            this.setState({
              user: data.data.user.role,
            }, () => {
                this.navigateAway()
            });
          });
        }
      } 

    //   componentDidUpdate(){
    //     this.navigateAway()
    //   }

    navigateAway(){
      const isUserLogged = Auth.loginCheck();
      if (!isUserLogged) window.location = "/login";
      if(this.state.user !== 'admin') this.props.history.push('/app')
    }

    render() {
      return <WrappedComponent user={{...this.state}} {...this.props} />;
    }
  };
}