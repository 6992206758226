// Thankyou page for the Pabbly redirect url
import React, { useEffect, useState } from 'react';
import axios from 'axios'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box, CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CONSTANT_CONTACT } from 'utility/enums';
import { Alert } from 'reactstrap';
import auth from 'actions/auth';
import { constantContactAuthURL } from 'utility/helpers';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}

export default function ConstantContact() {
  const classes = useStyles();
  const [isAuth, setAuth ] = useState('')
  const [loading, setLoading ] = useState({
    status: true,
    message: 'Authenticating...'
  })
  const [error, setError ] = useState({
    show: false,
    message: ''
  })

  useEffect(() => {
    setLoading({
      status: true,
      message: 'Fetching the subscription token...'
    })
    const isUserLogged = auth.loginCheck();
    if (!isUserLogged) window.location = "/login";
    const paramsObj = {}
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of params) {
      paramsObj[key] = value
    }
    if(paramsObj.code){
      confirmToken(paramsObj.code)
    }else{
      setLoading({
        status: false,
        message: 'There is no token found. Unable to authenticate.'
      })
    }
  }, [])
  
  const confirmToken = async (code, userId = null) => {
    setLoading({
      status: true,
      message: 'Confirming the Authorisation token...'
    })
    let header = {}
    const token = JSON.parse(localStorage.getItem("login"));
    if(!token){
      return setLoading({status: false, message: 'Please Login first!'})
    }else{
      header = {
        headers: {
          authorization: token.token
        }
      }
    }
    const url =`/api/integrations/constantContact/oauth`;
    const data = {
      code
    }

    await axios
        .post(url, data, header)
        .then( data => {
          setLoading({
            status: false,
            message: 'Authenticated!'
          })
          // console.log({data})
          // requestList()
          setAuth(true)
          // window.location.href = `/app/integrations?integration=${CONSTANT_CONTACT}`
        })
        .catch(e => {
          if(e.response){
            setError({status: true, message: e.response.data.message})
            return setLoading({
              status: false,
              message: e.response.data.message
            })
          }
          setError({status: true, message: 'Oops! Failed to verify token.'})
          setLoading({
            status: false,
            message: 'Failed...'
          })
        });
  }
  console.log(process.env)

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          GroupX Constant Contact Integration!
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" component="p">
          Please wait while we login you to Constant Contact.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" alignItems="center">
        <Grid >
          <Card className={classes.root}>
              <CardContent className={classes.cardContent}>
                <div>
                  <Typography gutterBottom variant="h5" component="h2">
                    {loading.message}
                  </Typography>
                  {(!loading.status&& !isAuth) &&  <Typography variant="body2" color="textSecondary" component="p">
                      Something went wrong! Please choose any options below.
                    </Typography>}
                    {loading.status && <CircularProgress />}
                </div>

                {(isAuth) && <Typography variant="body2" color="success" component="p">Choose any one action below.</Typography>}
                {error.status && <Alert color='danger'>{error.message}</Alert>}
              </CardContent>
              <hr/>
                <CardActions className={classes.cardContent}>
                  { (!loading.status && !isAuth)&&
                    <>
                      <Button variant='contained' size="large" color="secondary" onClick={e => window.location.href = constantContactAuthURL}
                      >
                        Re-Authenticate
                    </Button>
                      <Button size="small" color="primary" onClick={e => window.location.href = '/app/dashboard'}>
                        Go to dashboard
                      </Button>
                      </>
                    }
                  { (!loading.status && isAuth)&&
                    <>
                      <Button variant='contained' size="large" color="primary" onClick={e => window.location.href = `/app/integrations?integration=${CONSTANT_CONTACT}`}>
                          Add a new integration
                      </Button>
                      <Button size="small" color="secondary" onClick={e => window.location.href = '/app/dashboard'}>
                        Go back to dashboard
                      </Button>
                    </>
                    }
                  {loading.status && <CircularProgress size='2rem'/>}
            </CardActions>
          </Card>
        </Grid>
      </Container>

      <Box mt={5}>
            <Copyright />
      </Box>
     
    </React.Fragment>
  );
}
