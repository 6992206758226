import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Auth from "actions/auth";
import Logo from "assets/img/transparent-3.png";
import axios from "axios";
import {regEx } from 'utility/helpers';
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import image1 from 'assets/img/bg-3.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url("+image1+")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "green"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: green[50],
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function Register(props) {
  const isUserLogged = Auth.loginCheck();
  if (isUserLogged) props.history.push("/admin");
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [confirm, setConfirm] = useState("");
  const [lastName, setLastName] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false)
  const handleChange = event => {
    event.preventDefault();
    setError([])
    setLoading(false)
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "confirm":
        setConfirm(value);
        break;
      // case "company":
      //   setCompany(value);
      //   break;
      // case "contact":
      //   setContact(value);
      //   break;
      // case "username":
      //   setUsername(value);
      //   break;
      default:
        break;
    }
  };
  const formValid = () => {
    const valid = {
      status: true
    };
    const error = [];
    
    if (!firstName) {
      error.push({status: false,
        msg: "First Name required.",
        field: "firstName"});
    }
    if (!lastName) {
      error.push({
        status: false,
        msg: "Last Name required.",
        field: "lastName"
        });
    }
    // if (!username) {
    //   error.push({
    //     status: false,
    //     msg: "Username required.",
    //     field: "username"
    //     });
    // }
    if (!email)  error.push({ status: false, msg: "Email required." , field: "email"});
    if (email && !email.match(regEx.email))  error.push({ status: false, msg: "Please enter correct email.", field: "email"});
    if (!password)  error.push({ status: false, msg: "Password required." , field: "password"});
    if (!confirm || confirm !== password) {
      error.push({
        status: false,
        msg: "Password did not match.",
        field: "confirm"
      });
    }
    if (!recaptchaToken) { 
      error.push({
        status: false,
        msg: "Please verify recaptcha!",
        field: "recaptcha"
      });
    }

    return error;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true)
    const errors = formValid();
    if (errors.length !==0 )  {
      setError(errors);
      setLoading(false)
    }else{
      const data = {
        firstName,
        lastName,
        email: email.toLowerCase().trim(),
        password,
        // company,
        // username,
        // contactNumber: contact
      };
      axios
        .post(`/api/users`, data)
        .then(() => {
          setLoading(false)
          setError([{field: 'success', msg: 'Registration Completed! Confirm email link sent to your email address, ' + email}]);
          // props.history.push("/login");
          setEmail("")
          setPassword("")
          setFirstName("")
          setConfirm("")
          setLastName("")
          setRecaptchaToken(null)

        })
        .catch(e => {
          setLoading(false)
          setError([{field: 'server', msg: e.response.data.message}]);
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} alt="logo" width={"140px"} className={"mb-2"} />
          <Typography component="h1" variant="h5">
            Registration
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="lastName"
              label="Last Name"
              id="lastName"
              autoComplete="lastName"
              onChange={handleChange}
            />
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="username"
              label="Username"
              id="username"
              autoComplete="username"
              onChange={handleChange}
            /> */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              id="email"
              type="email"
              autoComplete="email"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="password"
              type="password"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm"
              label="Confirm Password"
              id="confirm"
              autoComplete="confirm"
              type="password"
              onChange={handleChange}
            />

          <ReCAPTCHA
              sitekey={'6LcC67kZAAAAAP9h00yPGQV5yGssGy5_ZiAjji5S'}
              onChange={e => setRecaptchaToken(e)}
            />
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="company"
              label="Company Name"
              id="company"
              autoComplete="company"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="contact"
              label="Contact Number"
              id="contact"
              autoComplete="contact"
              onChange={handleChange}
            /> */}
            {error.map(e=>{
               return <Alert
                variant={e.field !== 'success' ? "danger" : "success"}
                style={{ marginTop: 10 }}
                key={e.field}
              >
                {e.msg}
             </Alert>
            })}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={loading && <CircularProgress color='inherit'/>}
              disabled={loading}
            >
              Register
            </Button>
            <Grid container justify="center">
              
              <Grid item xs>
                <Link to="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
             
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
