
const regEx = {
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    tel: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    string: /[A-Z]/g,
}

let constantContactAuthURL = `https://api.cc.email/v3/idfed?client_id=71638af1-2f84-42e6-b4b0-08a941c5f769&redirect_uri=https://app.groupx.io/integrations/cc/oauth/callback&response_type=code&scope=contact_data+campaign_data`;

if(process.env.NODE_ENV === 'development'){
    constantContactAuthURL = `https://api.cc.email/v3/idfed?client_id=71638af1-2f84-42e6-b4b0-08a941c5f769&redirect_uri=https://localhost:3000/integrations/cc/oauth/callback&response_type=code&scope=contact_data+campaign_data`;
}

export { constantContactAuthURL, regEx }

 


