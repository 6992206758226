import React, {createContext, useEffect, useState} from 'react';
import Axios from 'axios';
import Auth from 'actions/auth';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState();
  useEffect( () => {
      // const isUserLogged = Auth.loginCheck();
      // console.log('COntext runing', isUserLogged)
      // if (!isUserLogged) window.location = "/login";
      fetchUser()
  }, [props])

  async function fetchUser(){
    const token = JSON.parse(localStorage.getItem("login"));
    if (token) {
      const id = Auth.decode("id");
      const url =`/api/users/${id}`;

      await Axios
        .get(url, {
          headers: {
            authorization: token.token
          }
        })
        .then(async ({data}) => {
          console.log(data)
            setUser({...data.user, subscriptions: data.subscriptions, tutorials: data.tutorials})
        }).catch(async e => {
          console.error(e)
          if(e.response){
           if(e.response.data.redirect){
              await Auth.logout();
              props.history.push('/login?msg=verifyEmail')
              // window.location = e.response.data.redirect;
           }else{
             await Auth.logout();
             props.history.push('/login?msg=Session has been expired, please log in again!')
           }
          }
        });
    }else{

    }
  }

  return <UserContext.Provider value={user}>
       {props.children}
   </UserContext.Provider>
}