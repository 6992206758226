import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Logo from "assets/img/transparent-3.png";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import Auth from "actions/auth";
import Alert from "react-bootstrap/Alert";
import image1 from 'assets/img/bg-3.png'
import { CircularProgress } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },

  image: {
    backgroundImage: "url("+image1+")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "green"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: green[50],
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function SignInSide(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false });

  const handleChange = event => {
    setAlert({show: false})
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value.toLowerCase().trim());
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };
  
  useEffect(() => {
    const isUserLogged = Auth.loginCheck();
    if (isUserLogged) window.location = "/app"  

    const paramsObj = {}
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of params) {
      paramsObj[key] = value
    }
    if(paramsObj.msg === 'verifyEmail'){
      setAlert({
        show: true,
        msg: `Please verify your email before login! If you haven't recieved your email, resend the confirmation email.`,
        link: '/resend-email'
      })
    }else if(paramsObj.msg === "getLoginDetails"){
      setAlert({
        show: true,
        msg: `You have successfully subscribed to the plan and registered to GroupX.
        We have sent you an email with login credentials and verification link.
        Please verify your email before login! 
        If you haven't recieved your email, resend the confirmation email.`,
        link: '/resend-email'
      })
    }else if(paramsObj.msg){
      setAlert({
        show: true,
        msg: paramsObj.msg
      })
    }
  }, [])

  const formValid = () => {
    const valid = {
      status: true
    };
    if (!email) return { status: false, msg: "Email required." };
    if (!password) return { status: false, msg: "Password required." };

    return valid;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setAlert({show: false})
    setLoading(true)
    const form = formValid();
    if (!form.status) {
      setAlert({ show: true, error: true, msg: form.msg });
      setTimeout(() => {
        setAlert({ show: false });
      }, 3000);
      setLoading(false)
      return;
    }
    const valid = await Auth.login({ email, password });
    if (!valid.status) {
      if(valid.redirect === 'email_verify') {
        setAlert({
          show: true,
          msg: 'Hey! your account has been created, Please login.'
          // msg: `Please verify your email before login! 
          // If you haven't recieved verification email, resend the confirmation email.`,
          // link: '/resend-email'
        })
      }else{
        setAlert({ show: true, error: true, msg: valid.message });
        setTimeout(() => {
          setAlert({ show: false });
        }, 3000);
      }
      return setLoading(false)
    }
    window.location = "/app" 
    setLoading(true)
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} alt="logo" width={"140px"} className={"mb-2"} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {alert.show ? (
              <Alert
                variant={alert.error ? "danger" : "success"}
                style={{ marginTop: 10 }}
              >
                {alert.msg}
                <br></br>
                {alert.link && <Link to={alert.link}>Click here</Link>}
              </Alert>
            ) : (
              <div style={{ marginTop: 10 }}>&nbsp;</div>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={loading && <CircularProgress color='inherit'/>}
              disabled={loading}
            >
              Sign In
            </Button>
            <Grid container justify="center">
              
              <Grid item xs>
                <Link to="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
             
              <Grid item>
                
               <Link to="/register" variant="body2">
                  {"Don't have an account? Sign Up Now"}
                </Link>
               
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
