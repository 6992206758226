export const  MAILCHIMP =  'mailChimp';
export const CONVERT_KIT = 'convertKit';
export const EMAIL = 'email';
export const CONSTANT_CONTACT = 'constantContact';
export const GET_RESPONSE = 'getResponse';
export const AWEBER = 'aweber';
export const SENDFOX = 'sendfox';
export const DRIP = 'drip';
export const ACTIVE_CAMPAIGN = 'activeCampaign';
export const GOOGLE_SHEET = 'google_sheet';
export const ZAPIER = 'zapier';
export const PIPE_DRIVE = 'pipedrive';
export const GO_HIGH_LEVEL = 'goHighLevel';
export const SENDINBLUE = 'sendInBlue';
export const DUBB = 'dubb';
export const TUTORIALS = {
    ADD_GROUP: 'add_group',
    GROUP_DETAILS: 'group_details',
    EXTENSION_DASH: 'extension_dashboard',
    ADD_INTEGRATION: 'add_integration',
    DOWNLOAD_EXTENSION: 'download_extension',
}


export const fields = {
    mailChimp: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    convertKit: [
            'name',
            'description',
            'apiKey',
            'listId',
            'secret'
        ],
    constantContact: [
            'name',
            'description',
            'listId'
        ],
    getResponse: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    aweber: [
            'name',
            'description',
            'apiKey',
            'apiUserId',
            'appURL',
            'secret',
            'listId'
        ],
    sendfox: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    drip: [
            'name',
            'description',
            'apiKey',
            'apiUserId',
            'appURL',
            'secret',
            'listId'
        ],
    activeCampaign: [
            'name',
            'description',
            'apiKey',
            'listId',
            'appURL',
        ],
    email: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    google_sheet: [
            'name',
            'description',
            'appURL',
        ],
    pipedrive: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    goHighLevel: [
            'name',
            'description',
            'apiKey',
            'listId'
        ],
    sendInBlue: [
            'name',
            'description',
            'apiKey',
        ],
    dubb: [
            'name',
            'description',
            'apiKey',
        ]
    };