import React, { useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/UI/Grid/GridItem.js";
import GridContainer from "components/UI/Grid/GridContainer.js";
import Card from "components/UI/Card/Card.js";
import CardBody from "components/UI/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Alert from "react-bootstrap/Alert";
import Auth from "actions/auth";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: green[50],
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function Dashboard() {
  const [alert, setAlert] = useState({ show: false });
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const classes = useStyles();
  const validate = async () => {
    const email = Auth.decode("email");
    const log = await Auth.login({ email, password: currentPassword });

    if (!log.status) {
      setAlert({ show: true, msg: "Invalid password.", error: true });
      setTimeout(() => {
        setAlert({ show: false });
      }, 3000);
      return false;
    }

    if (!newPassword) {
      setAlert({ show: true, msg: "Missing New Password.", error: true });
      setTimeout(() => {
        setAlert({ show: false });
      }, 3000);

      return false;
    }

    if (!confirmPassword || confirmPassword !== newPassword) {
      setAlert({ show: true, msg: "Password did not match.", error: true });
      setTimeout(() => {
        setAlert({ show: false });
      }, 3000);

      return false;
    }

    const opt = {
      data: {
        email,
        password: newPassword
      },
      headers: {
        authorization: JSON.parse(localStorage.getItem("login")).token
      }
    };

    axios
      .post(
       `/api/users/password`,
        opt.data,
        { headers: opt.headers }
      )
      .then(() => {
        setAlert({ show: true, msg: "Password updated.", error: false });
        setTimeout(() => {
          setAlert({ show: false });
          window.location = "/admin/change-password";
        }, 3000);
        return true;
      })
      .catch(e => {
        setAlert({ show: true, msg: e.response.data.message, error: true });
        setTimeout(() => {
          setAlert({ show: false });
        }, 3000);
        return false;
      });
  };
  const handleSubmit = event => {
    event.preventDefault();
    validate();
  };
  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "current_password":
        setCurrentPassword(value);
        break;
      case "new_password":
        setNewPassword(value);
        break;
      case "confirm_password":
        setConfirmPassword(value);
        break;

      default:
        break;
    }
  };
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="current_password"
                  label="Current Password"
                  type="password"
                  id="current_password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="new_password"
                  label="New Password"
                  type="password"
                  id="new_password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  id="confirm_password"
                  autoComplete="confirm-password"
                  onChange={handleChange}
                />
                {alert.show ? (
                  <Alert
                    variant={alert.error ? "danger" : "success"}
                    style={{ marginTop: 10 }}
                  >
                    {alert.msg}
                  </Alert>
                ) : (
                  <div style={{ marginTop: 10 }}>&nbsp;</div>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Update Password
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
