/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import 'font-awesome/css/font-awesome.min.css';
// core components
import Admin from "layouts/Admin";
import User from "layouts/User";
import Login from "layouts/Login";
import Register from "views/Auth/Register";
import ForgotPassword from "views/Auth/ForgotPassword";
import ResetPassword from "views/Auth/ResetPassword";
import ResendEmail from "views/Auth/ResendEmail";
import Thankyou from "views/Thankyou/Loadable";
import VerifyPage from "views/Verify/Loadable";
import PrivacyPage from "views/Privacy/Loadable";
import TOSPage from "views/TOS/Loadable";
import { UserProvider } from "contexts/userContext";
import ConstantContact from "views/Integrations/Pages/ConstantContact";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <UserProvider history={hist}>
      <Switch>
        <Route path="/app" component={User} />
        <Route path="/admin" component={Admin} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/email_verify" component={VerifyPage} />
        <Route exact path="/thankyou" component={Thankyou} />
        <Route exact path="/verify" component={VerifyPage} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/resend-email" component={ResendEmail} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/terms-of-services" component={TOSPage} />
        <Route exact path="/integrations/cc/oauth/callback" component={ConstantContact} />
        <Redirect from="/" to="/app" />
      </Switch>
    </UserProvider>
  </Router>,
  document.getElementById("root")
);
