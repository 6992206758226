import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Auth from "actions/auth";
import Logo from "assets/img/transparent-3.png";
import axios from "axios";
import image1 from 'assets/img/bg-3.png'
import {regEx } from '../../utility/helpers';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url("+image1+")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "green"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: green[50],
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}));

export default function ForgotPassword(props) {
  const isUserLogged = Auth.loginCheck();
  if (isUserLogged) props.history.push("/admin");

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([]);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true)
    if (!email)  {
      setError([{field: 'email', msg: 'Please enter your email!'}]);
      setLoading(false)
    }else{
      const data = {
        email
      };
      axios
        .post(`/api/users/resend-email-verification`, data)
        .then(() => {
          setLoading(false)
          setError([{field: 'success', msg: 'Email confirmation link has been sent to your email!'}]);
          // props.history.push("/login");
        })
        .catch(e => {
          setLoading(false)
          setError([{field: 'server', msg: e.response.data.message}]);
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} alt="logo" width={"140px"} className={"mb-2"} />
          <Typography component="h1" variant="h5">
            Resend Confirmation Email
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              id="email"
              type="email"
              autoComplete="email"
              onChange={e => setEmail(e.target.value)}
            />
            {error.map(e=>{
                if(e.field === 'success') return <FormHelperText key={e.field} variant='filled'  color='primary'>{e.msg}</FormHelperText>
                return <FormHelperText error key={e.field} required variant='filled'  color='secondary'>{e.msg}</FormHelperText>
            })}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={loading && <CircularProgress color='inherit'/>}
              disabled={loading}
            >
              Request Email Verification
            </Button>
            <Grid container justify="center">
              
              <Grid item xs>
                <Link to="/login" variant="body2">
                  Go back to Login
                </Link>
              </Grid>
             
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
