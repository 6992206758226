import React from 'react'
import Auth from "actions/auth"
import Axios from 'axios';

export default function(WrappedComponent, props) {

  return class extends React.Component {  
      state = {
              id: null,
              token: null,
          }

      componentDidMount() {
        const token = JSON.parse(localStorage.getItem("login"));
        if (token) {
          const id = Auth.decode("id");
          this.setState({id, token})
        }
        this.navigateAway()
      } 

      componentDidUpdate(){
        this.navigateAway()
      }

      navigateAway(){
        const isUserLogged = Auth.loginCheck();
        if (!isUserLogged) window.location = "/login";
      }

      render() {
        return <WrappedComponent user={{...this.state}} {...this.props} />;
      }
  
  };
}