import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Box, Typography, Container } from "@material-ui/core";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/UI/Navbars/Navbar.js";
import Sidebar from "components/UI/Sidebar/Sidebar.js";

import { adminRoutes } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-3.jpg";
import logo from "assets/img/transparent-4.png";
import Auth from "actions/auth.js";
import admin from "hoc/admin";

let ps;
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://www.groupx.io/" target="_blank">
        Group X
      </Link>{" "}
      {new Date().getFullYear()}
      {".  |   "}
      <Link color="inherit" to="/privacy" target="_blank">
        {"Privacy Policy"}
      </Link>{" "}
      {".  |   "}
      <Link color="inherit" to="/terms-of-services" target="_blank">
        {"Terms of Services"}
      </Link>{" "}
    </Typography>
  );
}


const switchRoutes = (
  <Switch>
    {adminRoutes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

function Admin(props, { ...rest }) {
  const isUserLogged = Auth.loginCheck();
  if (!isUserLogged) props.history.push("/login");
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("green");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <>
    <div className={classes.wrapper}>
      <Sidebar
        routes={adminRoutes}
        logoText={""}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={adminRoutes}
          handleDrawerToggle={handleDrawerToggle}
          passedProps={props}
          {...rest}
        />
        Admin
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
       {/* Footer */}
       <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </div>
      </>
  );
}


export default admin(Admin)