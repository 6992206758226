
// @material-ui/icons
import Dashboard from "@material-ui/icons/Assessment";
import Groups from "@material-ui/icons/Portrait";
import User from "@material-ui/icons/Person";
import {Payment, Group, VideoLibrary, Receipt, Extension, People, LocalOffer, VerifiedUser} from "@material-ui/icons";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

// core components/views for User layout
import ChangePasswordPage from "views/Auth/ChangePassword";
import DashboardPage from "views/Dashboard/Loadable";
import GroupsPage from "views/Groups/Loadable";
import GroupDetailsPage from "views/GroupDetails/Loadable";
import Integrations from "views/Integrations/Loadable";
import Plans from "views/Plans/Loadable";
import Download from "views/Download";
import PaymentsPage from "views/Payments";

// core components/views for Admin layout
import AdminDashboardPage from "views/Admin/Dashboard/Loadable";
import AdminGroupsPage from "views/Admin/Groups/Loadable";
import AdminGroupDetailsPage from "views/Admin/GroupDetails/Loadable";
import AdminIntegrations from "views/Admin/Integrations/Loadable";
import AdminUsers from "views/Admin/Users/Loadable";
import AdminAllMembers from "views/Admin/AllMembers/Loadable";
import AdminSubscriptions from "views/Admin/Subscriptions/Loadable";
import AdminPlans from "views/Admin/Plans/Loadable";
import Tutorials from "views/Admin/Tutorials/Loadable";
import AdminPayments from "views/Admin/Payments";
import NonVerifiedUsers from "views/Admin/NonVerifiedUsers";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/app"
  },
  {
    path: "/groups",
    name: "Groups",
    icon: People,
    component: GroupsPage,
    layout: "/app"
  },
  {
    path: "/integrations",
    name: "Integrations",
    icon: SettingsApplicationsIcon,
    component: Integrations,
    layout: "/app"
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: User,
    component: ChangePasswordPage,
    layout: "/app"
  },
  {
    path: "/group/:id",
    name: "Group Details",
    icon: User,
    component: GroupDetailsPage,
    layout: "/app",
    display: false
  },
  {
    path: "/plans",
    name: "Plans",
    icon: LocalOffer,
    component: Plans,
    layout: "/app",
    display: false
  },
  {
    path: "/download",
    name: "Groupx Extension",
    icon: Extension,
    component: Download,
    layout: "/app",
    display: false
  },
  {
    path: "/payment",
    name: "Payments",
    icon: Receipt,
    component: PaymentsPage,
    layout: "/app",
    display: false
  },
];

const adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: AdminDashboardPage,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: User,
    component: AdminUsers,
    layout: "/admin"
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: User,
    component: ChangePasswordPage,
    layout: "/admin"
  },
  // {
  //   path: "/verify-users",
  //   name: "Verify Users",
  //   icon: VerifiedUser,
  //   component: NonVerifiedUsers,
  //   layout: "/admin"
  // },
  {
    path: "/groups",
    name: "Groups",
    icon: Groups,
    component: AdminGroupsPage,
    layout: "/admin"
  },
  {
    path: "/integrations",
    name: "Integrations",
    icon: SettingsApplicationsIcon,
    component: AdminIntegrations,
    layout: "/admin"
  },
  {
    path: "/members",
    name: "Facebook User List",
    icon: Group,
    component: AdminAllMembers,
    layout: "/admin"
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: Payment,
    component: AdminSubscriptions,
    layout: "/admin"
  },
  {
    path: "/payment",
    name: "Payments",
    icon: Receipt,
    component: AdminPayments,
    layout: "/admin",
  },
  {
    path: "/group/:id",
    name: "Group Details",
    icon: User,
    component: AdminGroupDetailsPage,
    layout: "/admin",
    display: false
  },
  {
    path: "/plans",
    name: "Plans",
    icon: Payment,
    component: AdminPlans,
    layout: "/admin",
    display: false
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    icon: VideoLibrary,
    component: Tutorials,
    layout: "/admin",
  },
];


export {
  dashboardRoutes,
  adminRoutes
};
