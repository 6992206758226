
import React, {  useState, useContext, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea, CardMedia, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CloudDownload, LinkRounded, Shop } from '@material-ui/icons';
import { TUTORIALS } from 'utility/enums';
import { UserContext } from 'contexts/userContext';
import ReactPlayer from 'react-player';
// import SnackbarContent from 'components/UI/Snackbar/SnackbarContent';
// import AppBar from '@material-ui/core/AppBar';
// import Link from '@material-ui/core/Link';
// import Toolbar from '@material-ui/core/Toolbar';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
}));


export default function Download() {
  const contextUserValue = useContext(UserContext)
  const [videoTutorial, setVideoTutorial] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if(contextUserValue){
      let videoTut = contextUserValue.tutorials.length ? contextUserValue.tutorials.filter(tutorial => tutorial.type === TUTORIALS.DOWNLOAD_EXTENSION)[0] : null;
      setVideoTutorial(videoTut)
    }
  }, [contextUserValue])

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Download GroupX Extension!
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" component="p">
         You can install the groupX extension from the Google Chrome Store.
        </Typography>
      </Container>
      {/* End hero unit */}
      {!!videoTutorial && <Container className="pt-3 d-flex justify-content-center"> <ReactPlayer url={videoTutorial.videoURL} /></Container>}
      <Container maxWidth="sm" component="main">
        <div className='d-flex flex-column justify-content-around'>
            <a
              className='btn btn-success btn-lg my-2'
              target='_blank'
              href='https://chrome.google.com/webstore/detail/group-x/jkdffdchcndapkfobmkhoappiphlpboh'
              >
              Install via Google Chrome Store {" "}
              <Shop />
            </a>

            {/* <a
              target='_blank'
              className='btn btn-success btn-lg my-2'
              href='https://drive.google.com/file/d/1ZKVdPHFHp4G5C56xW_mjohAP_zj2QqBf/view?usp=sharing'
              >
              Direct Download {" "}
              <CloudDownload />
            </a> */}
          
        </div>
      </Container>
     
    </React.Fragment>
  );
}
